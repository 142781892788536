body {
  margin-top: 20px;
}

.accordion-style .card {
  background: transparent;
  box-shadow: none;
  margin-bottom: 15px;
  margin-top: 0 !important;
  border: none;
}

.accordion-style .card:last-child {
  margin-bottom: 0;
}

.accordion-style .card-header {
  border: 0;
  background: none;
  padding: 0;
  border-bottom: none;
  xborder-left: 1px solid #15395a;
  border-radius: 0%;
}

.accordion-style .btn-link {
  position: relative;
  display: block;
  width: 100%;
  font-size: 18px;
  text-align: left;
  white-space: normal;
  box-shadow: none;
  padding: 15px 55px;
  text-decoration: none;
  color: #ff914d;
}

.accordion-style .btn-link:hover {
  text-decoration: none;
}

.accordion-style .btn-link.collapsed {
  color: #1e2022;
}

.accordion-style .btn-link.collapsed:after {
  content: "+";
  left: 16px;
  right: inherit;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
  transform: none;
  width: 26px;
  top: 15px;
  text-align: center;

  color: #ff914d;
}

.accordion-style .btn-link:after {
  border: none;
  content: "-";
  left: 16px;
  right: inherit;
  font-size: 20px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  transform: none;
  width: 26px;
  top: 15px;
  position: absolute;
  color: #ff914d;
  text-align: center;
}

.accordion-style .card-body {
  padding: 20px;
  border-left: 1px solid #f03ac0;
}

@media screen and (max-width: 767px) {
  .accordion-style .btn-link {
    padding: 15px 40px 15px 55px;
  }
}

@media screen and (max-width: 575px) {
  .accordion-style .btn-link {
    padding: 15px 20px 15px 55px;
  }
}

.card-style1 {
  box-shadow: 0px 0px 10px 0px rgb(89 75 128 / 9%);
}

.border-0 {
  border: 0 !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
