body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Montserrat",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 20px;
  min-height: 100% !important;
}

.span-errors {
  color: #dc3545;
}

.upper-case {
  text-transform: uppercase;
}

.lower-case {
  text-transform: lowercase;
}

.gifuto-logo {
  width: 70px;
}

.navbar-hero-div {
  background-image: url("/public/img/squiggly-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1rem !important;
}

.navbar-div {
  background-image: url("/public/img/nav-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.navbar-button-tiny {
  font-weight: 600;
  letter-spacing: 0.05em;
  border-radius: 25px;
  background: #ee9b3c;
  color: white;
  width: 4rem;
}

.navbar-button {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 25px;
  background: #ee9b3c;
  color: white;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1.5rem;
  height: calc(2.5rem + 2px);
}

.navbar-button-nocolor {
  background-color: white;
  border-color: #ee9b3c;
  color: black;
  height: calc(2.5rem + 2px);
}

.nav-link {
  size: 16px;
  color: black;
}

.nav-link.active {
  color: #ee9124 !important;
}

/** Footer **/

#cb-cookie-banner {
  color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-radius: 0;
}

footer {
  background-color: #1e1e1e;
  color: #ffffff;
  border: none;
}

.footer-links,
.footer-rights-text {
  text-decoration: none;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.social-icons {
  color: #ffffff;
  font-size: 24px;
}

/** Home **/
.hero-small {
  text-transform: uppercase;
  line-height: 0;
}

.hero-medium {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.hero-heading {
  text-transform: uppercase;
  color: #ee9b3c;
}

.hero-sub-text {
  font-weight: 500;
  color: #000000;
}

.discount-div {
  background-image: url("/public/img/discount-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  backface-visibility: hidden;
  margin-top: -4rem !important;
}

.discount-giftcard-img {
  height: 475px;
  top: 694px;
  left: 750px;
}

.discount-small {
  color: #ee9b3c;
  line-height: 0;
}

.discount-big {
  line-height: 1;
}

.about-div {
  background-image: url("/public/img/about-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-img {
  margin-left: 0 !important;
}

.about-small,
.how-to-small,
.search-small {
  color: #ee9b3c;
  line-height: 0;
}

.search-small {
  line-height: normal;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}

.about-big,
.how-to-big,
.search-big {
  line-height: 1;
  text-transform: uppercase;
}

.how-to-div {
  xbackground-image: url("/public/img/how-to-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.how-to-img1 {
  height: 400px;
}
.landing-thumb {
  left: 50%;
  top: 50%;
  position: absolute;
  margin-top: -40px;
  margin-left: -30px;
}

.product-text,
.search-header1,
.search-header2,
.review-header,
.gifter-details-header1,
.gifter-details-header2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

.product-desc,
.review-header-desc {
  text-transform: capitalize;
}

.search-row-btn,
.btn-login-info {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 8px;
  background-color: #edca63;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 2.5rem;
}

.search-row-btn {
  border-radius: 20px;
}

.search-faq-div {
  background: linear-gradient(
      180deg,
      #fff8fb 33.96%,
      rgba(255, 248, 251, 0) 100%
    ),
    url(/public/img/faq-bg.png) no-repeat center;
}

.search-btn {
  background-color: #ee9b3c;
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
}

.search-header1,
.gifter-details-header1 {
  line-height: 50px;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.search-header2 {
  line-height: 30px;
  font-size: 25px;
  font-weight: 700;
  color: #d43157;
  text-transform: uppercase;
}

.search-results-header {
  text-transform: uppercase;
}

.gifter-details-header1 {
  line-height: 44px;
}

.gifter-details-header1,
.gifter-details-header2 {
  margin-top: 0px;
}

.review-subheader {
  color: #d43157;
}

.review-card {
  box-shadow: 0px 32px 64px -16px rgba(57, 59, 106, 0.06);
  border-radius: 12px;
  text-align: start;
}

.review-icons {
  font-size: 30px;
  color: #d43157;
}

.company-results-link {
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.company-results-card {
  background-color: transparent !important;
  border: none !important;
}

.company-results-card .gc-amt {
  color: #ff914d;
}

.company-results-logo {
  height: 5rem;
}

.font-awesome-icons {
  width: 40px;
}

.btn-bank-info,
.btn-contact-info {
  border: 1px solid #0a63a2;
  border-radius: 25px;
  font-weight: bold;
  width: 50%;
}

.div-login-info,
.div-company-info,
.div-bank-info,
.div-contact-info,
.div-giftee-details,
.div-search-results {
  font-size: 1rem !important;
  min-height: 75vh !important;
  /* min-height: 55vh !important; */
}

.div-qrcode-info {
  font-size: 16px;
  min-height: 100vh !important;
}

.qrcode-div {
  background-color: #2e5169;
  border-radius: 20px;
  print-color-adjust: exact;
}

.qrcode-business-name {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.qrcode-banner1 {
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
}

.qrcode-banner2 {
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
}

.qrcode-code {
  display: inline;
}

.qrcode-footer .logo {
  width: 8%;
}

.qrcode-footer .text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
  letter-spacing: 0.05em;
  text-align: center;
  color: white;
}

.qrcode-border {
  border: 1px solid #ffffff;
  width: 65%;
  display: inline-block;
  background-color: white;
}

.company-headers {
  font-weight: 600;
  color: #707070;
  font-size: 18px;
}

.form-floating > .form-floating > label,
.form-floating > label,
.form-floating > .form-select {
  font-size: 0.8rem;
}

.spl-label {
  padding: 1rem 1.75rem !important;
}

.spl-label-select {
  padding: 1.3rem 1rem !important;
  opacity: 1 !important;
  font-size: 1rem !important;
}

.after-select {
  opacity: 0.65;
  transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem) !important;
  font-size: 0.8rem !important;
  padding: 1.8rem 1rem !important;
}

.form-floating > .form-control {
  height: calc(2.5rem + 2px);
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  font-size: 0.8rem;
}

.form-login-controls > .form-floating > .form-control {
  height: calc(2.5rem + 2px);
  border-radius: 0px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  font-size: 0.8rem;
}

.form-floating > .form-select {
  height: calc(2.5rem + 2px);
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  padding-top: 1rem;
  padding-bottom: 0;
}

.form-floating > label {
  /* .form-login-controls > label { */
  padding: 0.75rem 0.75rem;
  font-size: 0.8rem;
}

.form-select:disabled {
  background-color: #ffffff;
  border: none;
}

.span-errors {
  font-size: 0.8rem !important;
  font-weight: bold;
}

.no-curve-border-right {
  border-top-right-radius: 0px 0px !important;
  border-bottom-right-radius: 0px 0px !important;
}

.no-curve-border-left {
  border-top-left-radius: 0px 0px !important;
  border-bottom-left-radius: 0px 0px !important;
}

.no-curve-border {
  border-radius: 0 !important;
}

.span-eye-pwd {
  cursor: pointer;
}
/*

.form-floating > .form-floating > .form-control {
  height: calc(2rem + -1px);
  font-size: 0.8rem;
}

.form-floating > .form-select {
  height: calc(3rem + -1px);
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  font-size: 0.8rem;
}

.form-floating > .form-floating > label {
  padding: 0.5rem 1.5rem;
  padding: 0.75rem 0.75rem;
} 

*/

.giftcard-box {
  text-align: center;
  display: inline-block;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  width: 20rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9px;
}

.giftcard-amt {
  text-align: right;
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  padding-right: 25px;
  font-family: "Inknut Antiqua";
}

.qrcode-img {
  height: 110px;
  margin-top: 8px;
  margin-bottom: 25px;
}

.giftcard-footer {
  text-align: right;
  line-height: 17px;
  padding-top: 20px;
  padding-right: 25px;
  padding-bottom: 5px;
}

.giftcard-footer-start {
  font-family: "Allura";
  font-size: 15px;
  font-weight: 400;
}

.giftcard-footer-logo {
  height: 9px;
  margin-left: 5px;
}

.giftcard-footer-end {
  font-family: "Montserrat";
  font-size: 8px;
  font-weight: 500;
}

.nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 40px;
}

#qr-code {
  height: 200px;
  width: 200px;
}

.qr-code-bkground {
  background-image: url("/public/img/No-QR-Red-Letter.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img-card-restaurant {
  margin: 10px 15px;
}

.span-amount-restaurant {
  margin: 15px 25px;
}

input[type="file"] {
  font-size: 13px;
}

.transactions-icon {
  width: "20px";
  height: "20px";
}

.expand-transactions-icon {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.ul-business-account {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.05em;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control:disabled {
  background-color: #ffffff;
  border: none;
}

.company-profile-edit {
  cursor: pointer;
}

.company-reg-complete {
  font-size: 25px;
  font-weight: 600;
}

.small-text {
  font-size: 0.8rem;
}

.dashboard-duration-select {
  font-size: 16px;
}

.dashboard-orange {
  color: #ef9c39 !important;
  font-size: 30px;
}

.dashboard-green {
  color: #28d095 !important;
}

.dashboard-blue {
  color: #085ed6 !important;
  font-size: 30px;
}

.sidebar-height {
  min-height: 70vh !important;
}

.dashboard-container {
  background-color: #f8f9fb;
  min-height: 75vh !important;
  border-radius: 20px;
  /* font-size: 0.8rem !important; */
}

.dashboard-bg-border {
  background-color: #f8f9fb !important;
  border-radius: 20px;
  border: none !important;
}

.react-datepicker__view-calendar-icon input {
  border-color: #ef9c39;
}

.table-responsive-maxheight-400px {
  max-height: 400px;
}

.user-reg-eye-pwd {
  margin-left: -12px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  xpadding: 0.375rem 1.5rem;
  padding: 0.375rem 1rem 0.375rem 2rem;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

th,
td {
  font-size: 14px;
  vertical-align: middle;
}

th.nowrap,
td.nowrap {
  white-space: nowrap;
}

.bgc-white {
  background-color: #fff !important;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent;
}

.search-company-name {
  text-wrap: nowrap;
}

.search-company-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 2px;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.651);
}

/* https://getbootstrap.com/docs/5.0/layout/breakpoints/ */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .navbar-div {
    background-image: none;
    margin-bottom: 2rem !important;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .navbar-div {
    background-image: none;
    margin-bottom: 2rem !important;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .navbar-div {
    background-image: none;
    margin-bottom: 2rem !important;
  }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* // XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width */

@media print {
  .no-print {
    display: none !important;
  }
}
